import React from 'react'
import ExpandablePanel from 'components/ExpandablePanel'

import FormTemplate from 'components/FormTemplate'
import {SectionDataTypes} from 'utils/sectionDataTypes'

const innovationsData: SectionDataTypes = {
  formType: 'innovations',
  title: 'Kryteria dla kategorii WIZJA I INNOWACJE, które Kapituła bierze pod uwagę przy wyborze laureatów:',
  subtitle: 'OSOBA NOMINOWANA WYRÓŻNIA SIĘ ZASTOSOWANIEM ODWAŻNEJ, NOWEJ I NIEKONWENCJONALNEJ IDEI BIZNESOWEj, NP:',
  listItems: [
    'opracowała nowatorski i odnoszący sukcesy produkt, usługę, model biznesowy, proces produkcyjny lub model badawczy;',
    'wyznacza nowe standardy w swojej branży lub zmienia dotychczasowy sposób jej funkcjonowania;',
    'dostrzega możliwości rynkowe tam, gdzie nie widzieli ich inni;',
    'skutecznie łączy ze sobą świat nauki i biznesu, wspierając badania i wykorzystując potencjał komercyjny ich wyników;',
    'konsekwentnie opiera strategię rozwoju firmy na inwestycjach w innowacje i nowe technologie;',
    'dzięki swoim badaniom naukowym lub innowacjom biznesowym osiągnęła sukces w skali europejskiej lub globalnej'
  ]
}

const Innovations = () => {

  return (
    <ExpandablePanel title='Wizja i innowacje'>
      <FormTemplate sectionData={innovationsData}/>
    </ExpandablePanel>
  )
}

export default Innovations


// isPublicCompany?: boolean
