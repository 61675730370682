import React, {useContext, useState} from 'react'
import styled, { css } from 'styled-components'
import Button from 'components/Button'
import AppContext, { AppContextTypes } from 'AppContext'
import { useNavigate } from 'react-router-dom'
import { pathOr } from 'ramda'
import ExpandablePanel from 'components/ExpandablePanel'
import { supabase } from 'utils/supabase'
import { v4 as uuidv4 } from 'uuid'
import toast from 'react-hot-toast'
import { format } from 'date-fns'
import {pl} from 'date-fns/locale'
import MiniCaptcha from 'components/MiniCaptcha'

const SummaryStep = () => {
  const { values } = useContext(AppContext) as AppContextTypes
  const navigate = useNavigate()
  const [isCaptchaValid, setIsCaptchaValid] = useState(false)

  const handleSetNextStep = () => {
    navigate('/podziekowanie')
  }

  const handleSetPreviousStep = () => {
    navigate('/formularze')
  }

  const handleFormSubmit = async () => {
    const now = new Date();
    const formattedDate = format(now, "dd-MM-yyyy HH-mm-ss", { locale: pl });

    const dataToInsert = {
      id: uuidv4(),
      submitted: formattedDate,
      basicdetails_firstname: values.basicDetails.firstName,
      basicdetails_lastname: values.basicDetails.lastName,
      basicdetails_companyname: values.basicDetails.companyName,
      basicdetails_email: values.basicDetails.email,
      basicdetails_phonenumber: values.basicDetails.phoneNumber,
      charity_firstname: values.charity.firstName,
      charity_lastname: values.charity.lastName,
      charity_companyname: values.charity.companyName,
      charity_email: values.charity.email,
      charity_phonenumber: values.charity.phoneNumber,
      charity_industry: values.charity.industry,
      charity_ispubliccompany: values.charity.isPublicCompany,
      charity_website: values.charity.website,
      charity_position: values.charity.position,
      charity_justification: values.charity.justification,
      innovations_firstname: values.innovations.firstName,
      innovations_lastname: values.innovations.lastName,
      innovations_companyname: values.innovations.companyName,
      innovations_email: values.innovations.email,
      innovations_phonenumber: values.innovations.phoneNumber,
      innovations_industry: values.innovations.industry,
      innovations_ispubliccompany: values.innovations.isPublicCompany,
      innovations_website: values.innovations.website,
      innovations_position: values.innovations.position,
      innovations_justification: values.innovations.justification,
      success_firstname: values.success.firstName,
      success_lastname: values.success.lastName,
      success_companyname: values.success.companyName,
      success_email: values.success.email,
      success_phonenumber: values.success.phoneNumber,
      success_industry: values.success.industry,
      success_ispubliccompany: values.success.isPublicCompany,
      success_website: values.success.website,
      success_position: values.success.position,
      success_justification: values.success.justification,
    }

    if (!isCaptchaValid) {
      toast.error('Wprowadź poprawny wynik równania.');
      return;
    }

    try {
      await supabase.from('prb_submissions_production').insert([dataToInsert]);
      handleSetNextStep();
      // Rest of your code
    } catch (error) {
      toast.error('Wystąpił błąd');
      console.log(error);
    }
  }

  const renderSectionSummary = (formKey: string, title: string) => {

    return (
      <ExpandablePanel title={title} alt>
        <Section>
          <SectionItem>
            <SectionItemLabel>Imię</SectionItemLabel>
            <SectionItemValue>{pathOr('---', [formKey, 'firstName'], values)}</SectionItemValue>
          </SectionItem>
          <SectionItem>
            <SectionItemLabel>Nazwisko</SectionItemLabel>
            <SectionItemValue>{pathOr('---', [formKey, 'lastName'], values)}</SectionItemValue>
          </SectionItem>
          <SectionItem>
            <SectionItemLabel>Nazwa firmy</SectionItemLabel>
            <SectionItemValue>{pathOr('---', [formKey, 'companyName'], values)}</SectionItemValue>
          </SectionItem>
          <SectionItem>
            <SectionItemLabel>Email</SectionItemLabel>
            <SectionItemValue>{pathOr('---', [formKey, 'email'], values)}</SectionItemValue>
          </SectionItem>
          <SectionItem>
            <SectionItemLabel>Numer telefonu</SectionItemLabel>
            <SectionItemValue>{pathOr('---', [formKey, 'phoneNumber'], values)}</SectionItemValue>
          </SectionItem>
          <SectionItem>
            <SectionItemLabel>Branża</SectionItemLabel>
            <SectionItemValue>{pathOr('---', [formKey, 'industry'], values)}</SectionItemValue>
          </SectionItem>
          <SectionItem>
            <SectionItemLabel>Spółka publiczna?</SectionItemLabel>
            <SectionItemValue>
              {pathOr(false, [formKey, 'isPublicCompany'], values) ? 'Tak' : 'Nie'}
            </SectionItemValue>
          </SectionItem>
          <SectionItem>
            <SectionItemLabel>Strona internetowa</SectionItemLabel>
            <SectionItemValue>
              <SectionItemValue>{pathOr('---', [formKey, 'website'], values)}</SectionItemValue>
            </SectionItemValue>
          </SectionItem>
          <SectionItem>
            <SectionItemLabel>Stanowisko</SectionItemLabel>
            <SectionItemValue>
              <SectionItemValue>{pathOr('---', [formKey, 'position'], values)}</SectionItemValue>
            </SectionItemValue>
          </SectionItem>
          <SectionItem column>
            <SectionItemLabel>Uzasadnienie</SectionItemLabel>
            <SectionItemValue>
              <SectionItemValue>{pathOr('---', [formKey, 'justification'], values)}</SectionItemValue>
            </SectionItemValue>
          </SectionItem>
        </Section>
      </ExpandablePanel>
    )
  }

  return values ? (
    <Wrapper>
      <PageTitle>Podsumowanie</PageTitle>
      <ExpandablePanel title='Dane osoby zgłaszającej' alt expanded>
        <Section>
          <SectionItem>
            <SectionItemLabel>Imię</SectionItemLabel>
            <SectionItemValue>{pathOr('---', ['basicDetails', 'firstName'], values)}</SectionItemValue>
          </SectionItem>
          <SectionItem>
            <SectionItemLabel>Nazwisko</SectionItemLabel>
            <SectionItemValue>{pathOr('---', ['basicDetails', 'lastName'], values)}</SectionItemValue>
          </SectionItem>
          <SectionItem>
            <SectionItemLabel>Email</SectionItemLabel>
            <SectionItemValue>{pathOr('---', ['basicDetails', 'email'], values)}</SectionItemValue>
          </SectionItem>
          <SectionItem>
            <SectionItemLabel>Nazwa firmy</SectionItemLabel>
            <SectionItemValue>{pathOr('---', ['basicDetails', 'companyName'], values)}</SectionItemValue>
          </SectionItem>
          <SectionItem>
            <SectionItemLabel>Numer telefonu</SectionItemLabel>
            <SectionItemValue>{pathOr('---', ['basicDetails', 'phoneNumber'], values)}</SectionItemValue>
          </SectionItem>
        </Section>
      </ExpandablePanel>
      {renderSectionSummary('innovations', 'Innowacje')}
      {renderSectionSummary('success', 'Sukces')}
      {renderSectionSummary('charity', 'Działalność społeczna')}
      <CaptchaWrapper>
      <MiniCaptcha onCaptchaValidation={setIsCaptchaValid} error={!isCaptchaValid} />
      </CaptchaWrapper>
        <ButtonsWrapper>
        <Button onClick={handleSetPreviousStep}>Wstecz</Button>
        <Button onClick={handleFormSubmit} disabled={!isCaptchaValid}>Wyślij zgłoszenie</Button>
      </ButtonsWrapper>
    </Wrapper>
  ) : null
}

export default SummaryStep


const Wrapper = styled.div`
  border: 1px solid ${({theme}) => theme.colors.primary};
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
  padding: 16px;
`

const Section = styled.div`
  margin-bottom: 10px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
`

const PageTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: left;
  color: ${({ theme }) => theme.colors.primary};
`

const SectionItem = styled.div<{
  column?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primaryLight};
  font-size: 14px;
  
  ${({ column }) => column && css`
    flex-direction: column;
    align-items: flex-start;
    & > div:first-of-type {
      margin-bottom: 8px;
    }
  `}
`

const SectionItemLabel = styled.div`
  color: ${({ theme }) => theme.colors.secondary};
`

const SectionItemValue = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
`
const CaptchaWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
