import React from 'react'
import AdminPageWrapper from 'admin/components/AdminPageWrapper'
import CharityCandidateForm from 'admin/components/CharityCandidateForm'

const AddCharityCandidate = () => {
  return (
    <AdminPageWrapper>
      <CharityCandidateForm />
    </AdminPageWrapper>
  )
}

export default AddCharityCandidate
