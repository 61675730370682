import React, { useEffect, useState } from 'react'
import { supabase } from 'utils/supabase'
import { VoterTypes } from 'admin/pages/Voters'
import VotersSubmissionsTable from 'admin/components/VotersSubmissionsTable'

const InitialVotingResults = () => {
  const [voters, setVoters] = useState<VoterTypes[] | []>([])

  const getVoters = async () => {
    try {
      const response = await supabase
        .from('voters')
        .select()

      const data = response.data as VoterTypes[]

      setVoters(data)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getVoters()
  }, [])

  return (
    <div>
      <VotersSubmissionsTable voters={voters} />
    </div>
  )
}

export default InitialVotingResults
