import React from 'react'
import { TableValues } from 'components/TableInput'
import styled from 'styled-components'
import { findTableSize } from 'utils/candidates'
import { isNotNilOrEmpty } from 'utils/ramda'

const PreviewTable = ({ data, comments }: { data: TableValues, comments?: string }) => {
    const size = findTableSize(data)

    const tableData = Array.from({ length: size.rows }, (_, rowIndex) =>
      Array.from({ length: size.columns }, (_, colIndex) => {
        const key = `td${rowIndex + 1}${colIndex + 1}`
        // @ts-ignore
        return data[key] || ''
      })
    )

  return (
    <TableWrapper>
      <Table>
        <thead>
        {[...Array(size.columns).keys()].map((col) => (
          // @ts-ignore
          <th key={`th${col + 1}`}>{data[`th${col + 1}`]}</th>
        ))}
        </thead>
        <tbody>
        {tableData.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((cell, colIndex) => (
              <td key={colIndex}>{cell}</td>
            ))}
          </tr>
        ))}
        </tbody>
      </Table>
      {isNotNilOrEmpty(comments) && (
        <TableComments dangerouslySetInnerHTML={{ __html: comments || '' }} />
      )}
    </TableWrapper>
  )
}

export default PreviewTable

const TableWrapper = styled.div`
  max-width: 100%;
  overflow-y: auto;
`

const Table = styled.table`
  border-spacing: 0;
  font-size: 14px;
  
  th {
    width: 150px;
    text-align: left;
    padding: 16px;
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    white-space: nowrap;
  }
  
  td {
    height: 35px;
    width: 150px;
    padding: 8px 16px;
    border: 1px solid #ccc;
    white-space: nowrap;
  }
`

const TableComments = styled.div`
  margin-top: 16px;
  font-size: 14px;
  color: #8c8c8c;
`
