import React, { useEffect, useState } from 'react'
import { supabase } from 'utils/supabase'
import { useParams } from 'react-router-dom'
import AdminPageWrapper from 'admin/components/AdminPageWrapper'
import InnovationCandidateForm from 'admin/components/InnovationCandidateForm'

const EditInnovationCandidate = () => {
  const [candidate, setCandidate] = useState(undefined)
  const { id } = useParams()

  const fetchCandidate = async () => {
    try {
      const response = await supabase.from('candidates').select().eq('id', id)
      const data = response.data ? response.data[0] : null

      if (data) {
        setCandidate(data)
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    fetchCandidate()
  }, [id])

  return (
    <AdminPageWrapper>
      <InnovationCandidateForm candidate={candidate} />
    </AdminPageWrapper>
  )
}

export default EditInnovationCandidate
