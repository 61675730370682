import React from 'react'
import ExpandablePanel from 'components/ExpandablePanel'

import FormTemplate from 'components/FormTemplate'
import { SectionDataTypes } from 'utils/sectionDataTypes'

const charityData: SectionDataTypes = {
  formType: 'charity',
  title: 'Kryteria dla kategorii DZIAŁALNOŚĆ SPOŁECZNA, które Kapituła bierze pod uwagę przy wyborze laureatów:',
  subtitle: 'OSOBA NOMINOWANA REALIZUJE DZIAŁANIA SPOŁECZNE I FILANTROPIJNE, NP.:',
  listItems: [
    'aktywnie angażuje się w inicjatywy charytatywne, społeczne lub obywatelskie;',
    'zmienia rzeczywistość społeczną poprzez pomoc osobom i środowiskom najbardziej potrzebującym;',
    'walczy z nierównościami i wykluczeniem społecznym;',
    'działa na rzecz wysokich standardów życia publicznego i rozwoju społeczeństwa obywatelskiego;',
    'angażuje się w przełamywanie społecznego tabu lub poszerzanie świadomości społecznej;',
    'promuje i wprowadza innowacje społeczne.'
  ]
}

const Innovations = () => {

  return (
    <ExpandablePanel title='Działalność Społeczna'>
      <FormTemplate sectionData={charityData} />
    </ExpandablePanel>
  )
}

export default Innovations


// isPublicCompany?: boolean
