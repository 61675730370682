import React from 'react'
import styled from 'styled-components'

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode
  disabled?: boolean
  type?: 'submit' | 'button'
}

const Button = (props: ButtonProps) => {
  const {
    onClick,
    children,
    disabled,
  } = props

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick && onClick(event)
  }

  return (
    <StyledButton
      {...props}
      disabled={disabled}
      onClick={disabled ? () => {} : handleClick}
    >
      <span>{children}</span>
    </StyledButton>
  )
}

export default Button

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 10px;
  outline: none;
  box-sizing: border-box;
  padding: 8px 16px;
  min-width: 100px;
  margin: 15px 0;
  line-height: 16px;
  border: 3px solid ${({ theme }) => theme.colors.primary};
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.4s;
  font-size: 14px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme }) => theme.colors.white};
  
  &:disabled {
    cursor: not-allowed;
    border-color: #ccc;
    color: #ccc;
  }

  &:hover:enabled {
    color: ${({ theme }) => theme.colors.white};;
    background-color: ${({ theme }) => theme.colors.primary};
  }

  &:active:enabled {
  }
`
