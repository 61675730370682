import React, { useState } from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

interface VotingRadioProps {
  onRadioChange: (selectedValue: string) => void
  value: string
  disabled: boolean
}

const VotingRadio: React.FC<VotingRadioProps> = ({ onRadioChange, value, disabled }) => {

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    onRadioChange(selectedValue);
  }

  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value || '0'}
        onChange={handleChange}
      >
        <FormControlLabel value='0' control={<Radio />} label='0' disabled={disabled} />
        <FormControlLabel value='1' control={<Radio />} label='1' disabled={disabled} />
        <FormControlLabel value='2' control={<Radio />} label='2' disabled={disabled} />
        <FormControlLabel value='3' control={<Radio />} label='3' disabled={disabled} />
        <FormControlLabel value='4' control={<Radio />} label='4' disabled={disabled} />
      </RadioGroup>
    </FormControl>
  );
}

export default VotingRadio
