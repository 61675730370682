import React, { useState } from 'react'
import { VoterTypes } from 'admin/pages/Voters'
import Modal from 'components/Modal'
import { Tooltip } from '@mui/material'
import { IconListCheck } from '@tabler/icons-react'
import styled from 'styled-components'

const VotingLinkModal = ({ voter }: { voter: VoterTypes }) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Tooltip title='Link do głosowania'>
        <IconWrapper onClick={handleOpen}>
          <IconListCheck />
        </IconWrapper>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        title='Link do głosowania'
      >
        <Title>Unikalne linki dla <span>{voter.name}</span>:</Title>
        <Voting>
          <VotingName>
            Preselekcja (tak/nie)
          </VotingName>
          <Value>{`${process.env.REACT_APP_URL}/preselekcja/${voter.code}`}</Value>
        </Voting>
        <Voting>
          <VotingName>
            Kapituła (0-4)
          </VotingName>
          <Value>{`${process.env.REACT_APP_URL}/kapitula/${voter.code}`}</Value>
        </Voting>
      </Modal>
    </>
  )
}

export default VotingLinkModal

const IconWrapper = styled.div`
  cursor: pointer;
`

const Title = styled.div`
  margin-bottom: 16px;
  
  span {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.secondary};
  }
`

const Value = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.primary};
`

const VotingName = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  color: #91918e;
`

const Voting = styled.div`
  margin-bottom: 20px;
`
