import React from 'react'
import styled from 'styled-components'

const Instructions = () => {
  return (
    <Wrapper>
      <div>
        Zapraszamy do zgłaszania nominacji w 13. edycji Nagrody PRB. Prosimy o podanie możliwie dużej liczby informacji,
        co ułatwi nam opracowanie zgłoszenia. Aby przesłać formularz, nie trzeba wypełniać wszystkich pól.
      </div>
    </Wrapper>
  )
}

export default Instructions

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: ${({ theme }) => theme.colors.primary};;
  font-size: 18px;
  margin-bottom: 30px;
  line-height: 22px;
`
