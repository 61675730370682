import React, {useContext, useMemo, useState} from 'react'
import styled from 'styled-components'
import {Checkbox, FormControlLabel, InputAdornment, TextField} from '@mui/material'
import {defaultValues} from 'utils/defaultValues'
import Button from 'components/Button'
import AppContext, {AppContextTypes} from 'AppContext'
import {useNavigate} from 'react-router-dom'
import {mobileCss} from 'utils/theme'

const PersonalDetailsStep = () => {
  const {values, formValueChange} = useContext(AppContext) as AppContextTypes
  const [rulesAccepted, setRulesAccepted] = useState(false)
  const [validationVisible, setValidationVisible] = useState(0)
  const navigate = useNavigate()

  const handleSetNextStep = () => {
    navigate('/formularze')
  }

  const handleValidation = () => {
    setValidationVisible((prev: number) => prev + 1)
  }

  const handleInputChange = (path: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    formValueChange(path, e.target.value)
    handleValidation()
  }

  const correctName = values?.basicDetails?.firstName && /^[\p{L}\d\s]{3,}$/u.test(values?.basicDetails?.firstName)
  const correctLastName = values?.basicDetails?.lastName && /^[\p{L}\d\s]{3,}$/u.test(values?.basicDetails?.lastName)
  const correctEmail = values?.basicDetails?.email && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values?.basicDetails?.email)

  const validPersonalDetails = correctEmail && correctName && correctLastName

  const isButtonAvailable = useMemo(() => {

    return rulesAccepted && validPersonalDetails
  }, [rulesAccepted, validPersonalDetails])

  return (
    <Wrapper>
      <StepTitle>Dane osoby zgłaszającej nominację</StepTitle>
      <PersonalDetailsForm>
        <Column>
          <Row>
            <TextField
              required
              fullWidth
              value={values?.basicDetails?.firstName || ''}
              onChange={handleInputChange('basicDetails.firstName')}
              label='Twoje imię'
              defaultValue={defaultValues}
              variant='outlined'
              error={Boolean(validationVisible && !correctName)}
              helperText={validationVisible && !correctName ? 'Podanie imienia jest wymagane' : null}
            />
          </Row>
          <Row>
            <TextField
              required
              fullWidth
              value={values?.basicDetails?.lastName || ''}
              onChange={handleInputChange('basicDetails.lastName')}
              label='Twoje nazwisko'
              defaultValue={defaultValues}
              variant='outlined'
              error={Boolean(validationVisible && !correctLastName)}
              helperText={!correctLastName ? 'Podanie nazwiska jest wymagane' : null}
            />
          </Row>
          <Row>
            <TextField
              required
              fullWidth
              value={values?.basicDetails?.email || ''}
              onChange={handleInputChange('basicDetails.email')}
              label='Twój adres email'
              defaultValue={defaultValues}
              variant='outlined'
              error={Boolean(validationVisible && !correctEmail)}
              helperText={!correctEmail ? 'Podanie adresu e-mail jest wymagane' : null}
            />
          </Row>
        </Column>
        <Column>
          <Row>
            <TextField
              fullWidth
              value={values?.basicDetails?.phoneNumber || ''}
              onChange={handleInputChange('basicDetails.phoneNumber')}
              InputProps={{
                startAdornment: <InputAdornment position='start'>+48</InputAdornment>
              }}
              label='Twój numer telefonu (opcjonalnie)'
              defaultValue={defaultValues}
              variant='outlined'
            />
          </Row>
          <Row>
            <TextField
              fullWidth
              value={values?.basicDetails?.companyName || ''}
              onChange={handleInputChange('basicDetails.companyName')}
              label='Nazwa firmy/organizacji (opcjonalnie)'
              defaultValue={defaultValues}
              variant='outlined'
            />
          </Row>
        </Column>
      </PersonalDetailsForm>
      <StepDescription>
        Administratorem Państwa danych osobowych jest stowarzyszenie Polska Rada Biznesu – Polish Business Roundtable z
        siedzibą w Warszawie (00-580), przy Al. Szucha 17. Państwa dane osobowe będą przetwarzane w celu i w zakresie
        niezbędnym do przeprowadzenia konkursu o Nagrodę Polskiej Rady Biznesu. Zasady przetwarzania Państwa danych
        osobowych, w tym uprawnienia z tym związane, opisane są w{' '}<InlineLink target='_blank' rel='noopener noreferrer' href='https://nagrodaprb.pl/regulamin/'> Regulaminie Nagrody PRB</InlineLink>. Kontakt z administratorem
        danych osobowych jest możliwy poprzez adres email:{' '}<InlineLink href='mailto: nagroda.prb@prb.pl'>nagroda.prb@prb.pl</InlineLink>.
      </StepDescription>
      <StyledCheckboxLabel
        control={(
          <Checkbox
            checked={rulesAccepted}
            onChange={(e) => {
              setRulesAccepted(e.target.checked)
            }}
          />
        )}
        label='Akceptuję Regulamin Nagrody PRB określający zasady zgłaszania kandydatur do nagrody oraz administrowanie moimi danymi
osobowymi.'
      />
      <ButtonsWrapper>
        <Button
          onClick={handleSetNextStep}
          disabled={!isButtonAvailable}
        >
          Dalej
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  )
}

export default PersonalDetailsStep

const Wrapper = styled.div`
  border: 1px solid ${({theme}) => theme.colors.primary};
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
  padding: 16px;
`

const PersonalDetailsForm = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  justify-content: center;

  ${mobileCss(`
    grid-template-columns: 1fr;
  `)}
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  margin-bottom: 16px;
  min-height: 80px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const StepDescription = styled.div`
  margin: 20px 0;
  font-size: 14px;
  font-family: 'Ubuntu', sans-serif;
  line-height: 1.5;

  ${mobileCss(`
    margin: 0 0 15px 0;
  `)}
`

const StepTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: left;
  color: ${({theme}) => theme.colors.primary};
`

const StyledCheckboxLabel = styled(FormControlLabel)`
  .MuiTypography-root {
    font-family: 'Ubuntu', sans-serif;
    font-size: 14px !important;
    font-weight: normal;
  }
`
const InlineLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
`
