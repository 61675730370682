import React from 'react'
import ReactQuill from 'react-quill'
import styled from 'styled-components'

interface WysiwygEditorProps {
  name: string
  value: string
  label: string
  onChange: (name: string, value: string) => void
}

const WysiwygEditor = ({ name, value, onChange, label }: WysiwygEditorProps) => {
  const toolbarOptions = [
    ['bold', 'italic', 'underline'],
    ['link'],
    [{ 'size': [] }],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],
    [{ 'color': ['#fff', '#bbb', '#c59b51', '#00265d', '#cc0000', '#32a84e'] }],
    [{ 'align': [] }]
  ]

  const handleValueChange = (value: string) => {
    onChange(name, value)
  }

  return (
    <div>
      <EditorLabel>{label}</EditorLabel>
      <ReactQuill
        theme='snow'
        value={value}
        onChange={handleValueChange}
        modules={{ toolbar: toolbarOptions }}
      />
    </div>
  )
}

export default WysiwygEditor

const EditorLabel = styled.div`
  margin-bottom: 8px;
`
