import React, { ChangeEvent, useState } from 'react'
import Modal from 'components/Modal'
import { Button, TextField } from '@mui/material'
import { supabase } from 'utils/supabase'
import { v4 as uuidv4 } from 'uuid'
import { generateRandomCode } from 'utils/common'
import toast from 'react-hot-toast'
import styled from 'styled-components'

const initialValues = {
  name: '',
  email: '',
  votes: {},
  votes_initial: {}
}

const AddVoterModal = ({ callback }: { callback: () => void }) => {
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState(initialValues)

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setValues(initialValues)
    setOpen(false)
  }

  const handleValueChange = (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setValues(prev => ({ ...prev, [name]: e.target.value }))
  }

  const handleSubmit = async () => {
    try {
      const id = uuidv4()
      await supabase.from('voters').insert([{
        ...values,
        id,
        code: generateRandomCode()
      }])
      handleClose()
      callback()
      toast.success('Pomyślnie dodano głosującego')
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <Button variant='outlined' onClick={handleOpen}>Dodaj głosującego</Button>
      <Modal
        title='Dodaj głosującego'
        open={open}
        onClose={handleClose}
      >
        <FormWrapper>
          <TextField
            size='small'
            required
            fullWidth
            value={values.name}
            onChange={handleValueChange('name')}
            label='Nazwa'
            variant='outlined'
          />
          <TextField
            size='small'
            required
            fullWidth
            value={values.email}
            onChange={handleValueChange('email')}
            label='Email'
            variant='outlined'
          />
        </FormWrapper>
        <ButtonsWrapper>
          <Button variant='outlined' onClick={handleClose}>Anuluj</Button>
          <Button variant='contained' color='primary' onClick={handleSubmit}>Dodaj</Button>
        </ButtonsWrapper>
      </Modal>
    </>
  )
}

export default AddVoterModal

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-top: 30px;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px 0;
`
