import React, { useEffect, useState } from 'react'
import AdminPageWrapper from 'admin/components/AdminPageWrapper'
import { supabase } from 'utils/supabase'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from '@mui/material'
import { SuccessCandidateTypes } from 'admin/components/SuccessCandidateForm'
import CandidatePreview from 'admin/components/CandidatePreview'

const SingleCandidatePreview = () => {
  const [candidate, setCandidate] = useState<SuccessCandidateTypes | null>(null)
  const { id } = useParams()
  const navigate = useNavigate()

  const fetchCandidate = async () => {
    try {
      const response = await supabase.from('candidates').select().eq('id', id)
      const data = response.data ? response.data[0] : null

      if (data) {
        setCandidate(data)
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    fetchCandidate()
  }, [id])

  return (
    <AdminPageWrapper>
      <Header>
        <Button variant='outlined' onClick={() => navigate('/admin/kandydaci')}>Powrót</Button>
      </Header>
      <CandidatePreview candidate={candidate} />
    </AdminPageWrapper>
  )
}

export default SingleCandidatePreview

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`
