import React from 'react'
import styled from 'styled-components'
import Button from 'components/Button'
import Innovations from 'components/Innovations'
import Success from 'components/Success'
import Charity from 'components/Charity'
import { useNavigate } from 'react-router-dom'
import Instructions from 'components/Instructions'

const AdditionalCategoriesStep = () => {
  const navigate = useNavigate()

  const handleSetNextStep = () => {
    navigate('/podsumowanie')
  }

  const handleSetPreviousStep = () => {
    navigate('/')
  }

  return (
    <Wrapper>
      <StepTitle>Zgłoszenia</StepTitle>
      <Instructions />
      <Innovations />
      <Success />
      <Charity />
      <ButtonsWrapper>
        <Button onClick={handleSetPreviousStep}>Wstecz</Button>
        <Button onClick={handleSetNextStep}>Dalej</Button>
      </ButtonsWrapper>
    </Wrapper>
  )
}

export default AdditionalCategoriesStep

const Wrapper = styled.div`
  border: 1px solid ${({theme}) => theme.colors.primary};
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
  padding: 16px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
`


const StepTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: left;
  color: ${({ theme }) => theme.colors.primary};
`
