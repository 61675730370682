import React, {useEffect, useState} from 'react'
import {getCookie, setCookie} from 'typescript-cookie'
import Button from 'components/Button'
import styled from 'styled-components'
import {mobileCss} from 'utils/theme'

const Cookies = () => {
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    const cookieValue = getCookie('accept-cookies')
    if (cookieValue === 'true' || cookieValue === 'false') {
      setIsVisible(false)
    }
  }, [])

  const handleAccept = () => {
    setCookie('accept-cookies', 'true', {expires: 7})
    setIsVisible(false)
  }

  const handleReject = () => {
    setCookie('accept-cookies', 'false', {expires: 7})
    setIsVisible(false)
  }

  return isVisible ? (
    <CookiesBanner>
      <BannerText>Ta strona internetowa wykorzystuje pliki cookie w celu zapewnienia najlepszej jakości usług.
        Przeglądając tę witrynę, zgadzasz się na korzystanie z plików cookie zgodnie z naszą Polityką Prywatności.
      </BannerText>
      <ButtonsContainer>
        <Button onClick={handleAccept}>Zgoda</Button>
        <Button onClick={handleReject}>Odrzuć</Button>
      </ButtonsContainer>
    </CookiesBanner>
  ) : null
}

export default Cookies

const CookiesBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: fixed;
  bottom: 0;
  width: 100%;
  background: #333;
  color: #fff;
  padding: 10px 30px;
  text-align: center;

  ${mobileCss(`
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0; /* Remove margin for centering */
  `)}
`


const BannerText = styled.div`
  margin: 0 15px;
`
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-right: 80px;

  ${mobileCss(`
    margin-right: 0;
  `)}
`
