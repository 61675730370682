import React from 'react'
import styled from 'styled-components'
import Button from 'components/Button'

const Farewell = () => {

  const handleRedirect = () => {
    window.location.href = 'https://www.nagrodaprb.pl'
  }

  return (
    <Wrapper>
      <ThankYou>Zapisaliśmy Twoje zgłoszenie. Dziękujemy za czas poświęcony na wypełnienie formularza.</ThankYou>
      <Button onClick={handleRedirect}>Powrót na stronę PRB</Button>
    </Wrapper>
  )
}

export default Farewell

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  border: 2px solid ${({theme}) => theme.colors.primary};
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
  min-height: 150px;
  padding: 26px;
  margin-top: 150px;
`

const ThankYou = styled.div`
  color: ${({ theme }) => theme.colors.primary};
`
