import React from 'react'
import AdminPageWrapper from 'admin/components/AdminPageWrapper'

const Entries = () => {
  return (
    <AdminPageWrapper>
      zgłoszenia
    </AdminPageWrapper>
  )
}

export default Entries
