import React, { ChangeEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { equals, not } from 'ramda'

interface TableInputProps {
  name: string
  label: string
  value: TableValues
  onChange: (name: string, value: TableValues) => void
}

export interface TableValues {
  th1: string
  th2: string
  th3: string
  th4: string
  th5: string
  td11: string
  td12: string
  td13: string
  td14: string
  td15: string
  td21: string
  td22: string
  td23: string
  td24: string
  td25: string
  td31: string
  td32: string
  td33: string
  td34: string
  td35: string
  td41: string
  td42: string
  td43: string
  td44: string
  td45: string
  td51: string
  td52: string
  td53: string
  td54: string
  td55: string
}

const TableInput = ({ name, label, value, onChange }: TableInputProps) => {
  const [values, setValues] = useState<TableValues>({
    th1: '',
    th2: '',
    th3: '',
    th4: '',
    th5: '',
    td11: '',
    td12: '',
    td13: '',
    td14: '',
    td15: '',
    td21: '',
    td22: '',
    td23: '',
    td24: '',
    td25: '',
    td31: '',
    td32: '',
    td33: '',
    td34: '',
    td35: '',
    td41: '',
    td42: '',
    td43: '',
    td44: '',
    td45: '',
    td51: '',
    td52: '',
    td53: '',
    td54: '',
    td55: '',
  })

  useEffect(() => {
    if (not(equals(value, values))) {
      setValues(value)
    }
  }, [value])

  const handleValueChange = (name: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setValues(prev => ({ ...prev, [name]: e.target.value }))
  }

  useEffect(() => {
    onChange(name, values)
  }, [values])

  return (
    <Wrapper>
      <Label>{label}</Label>
      <InputDescription>
        Uzupełnij tabelę. Pominięcie całego wiersza lub kolumny sprawi, że nie wyświetlą się one w broszurze.
      </InputDescription>
      <Table>
        <thead>
          <tr>
            <th>
              <StyledInput name='th1' value={values.th1} onChange={handleValueChange('th1')} />
            </th>
            <th>
              <StyledInput name='th2' value={values.th2} onChange={handleValueChange('th2')} />
            </th>
            <th>
              <StyledInput name='th3' value={values.th3} onChange={handleValueChange('th3')} />
            </th>
            <th>
              <StyledInput name='th4' value={values.th4} onChange={handleValueChange('th4')} />
            </th>
            <th>
              <StyledInput name='th5' value={values.th5} onChange={handleValueChange('th5')} />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <StyledInput name='td11' value={values.td11} onChange={handleValueChange('td11')} />
            </td>
            <td>
              <StyledInput name='td12' value={values.td12} onChange={handleValueChange('td12')} />
            </td>
            <td>
              <StyledInput name='td13' value={values.td13} onChange={handleValueChange('td13')} />
            </td>
            <td>
              <StyledInput name='td14' value={values.td14} onChange={handleValueChange('td14')} />
            </td>
            <td>
              <StyledInput name='td15' value={values.td15} onChange={handleValueChange('td15')} />
            </td>
          </tr>
          <tr>
            <td>
              <StyledInput name='td21' value={values.td21} onChange={handleValueChange('td21')} />
            </td>
            <td>
              <StyledInput name='td22' value={values.td22} onChange={handleValueChange('td22')} />
            </td>
            <td>
              <StyledInput name='td23' value={values.td23} onChange={handleValueChange('td23')} />
            </td>
            <td>
              <StyledInput name='td24' value={values.td24} onChange={handleValueChange('td24')} />
            </td>
            <td>
              <StyledInput name='td25' value={values.td25} onChange={handleValueChange('td25')} />
            </td>
          </tr>
          <tr>
            <td>
              <StyledInput name='td31' value={values.td31} onChange={handleValueChange('td31')} />
            </td>
            <td>
              <StyledInput name='td32' value={values.td32} onChange={handleValueChange('td32')} />
            </td>
            <td>
              <StyledInput name='td33' value={values.td33} onChange={handleValueChange('td33')} />
            </td>
            <td>
              <StyledInput name='td34' value={values.td34} onChange={handleValueChange('td34')} />
            </td>
            <td>
              <StyledInput name='td35' value={values.td35} onChange={handleValueChange('td35')} />
            </td>
          </tr>
          <tr>
            <td>
              <StyledInput name='td41' value={values.td41} onChange={handleValueChange('td41')} />
            </td>
            <td>
              <StyledInput name='td42' value={values.td42} onChange={handleValueChange('td42')} />
            </td>
            <td>
              <StyledInput name='td43' value={values.td43} onChange={handleValueChange('td43')} />
            </td>
            <td>
              <StyledInput name='td44' value={values.td44} onChange={handleValueChange('td44')} />
            </td>
            <td>
              <StyledInput name='td45' value={values.td45} onChange={handleValueChange('td45')} />
            </td>
          </tr>
          <tr>
            <td>
              <StyledInput name='td51' value={values.td51} onChange={handleValueChange('td51')} />
            </td>
            <td>
              <StyledInput name='td52' value={values.td52} onChange={handleValueChange('td52')} />
            </td>
            <td>
              <StyledInput name='td53' value={values.td53} onChange={handleValueChange('td53')} />
            </td>
            <td>
              <StyledInput name='td54' value={values.td54} onChange={handleValueChange('td54')} />
            </td>
            <td>
              <StyledInput name='td55' value={values.td55} onChange={handleValueChange('td55')} />
            </td>
          </tr>
        </tbody>
      </Table>
    </Wrapper>
  )
}

export default TableInput

const Wrapper = styled.div`
  
`

const Label = styled.div`
  margin-bottom: 4px;
`

const InputDescription = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
  color: #999;
`

const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  background-color: transparent;
`

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  font-size: 14px;

  th {
    text-align: left;
    padding: 16px;
    background-color: #f2f2f2;
    border: 1px solid #ccc;
  }

  td {
    padding: 8px 16px;
    border: 1px solid #ccc;
  }
`
