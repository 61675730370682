import React, { ReactNode, useState } from 'react'
import { Collapse } from '@mui/material'
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react'
import styled, { css } from 'styled-components'

interface ExpandablePanelProps {
  title: string
  children: ReactNode | string
  disabled?: boolean
  expanded?: boolean
  alt?: boolean
}

const ExpandablePanel = ({ title, children, disabled, expanded = false, alt = false }: ExpandablePanelProps) => {
  const [open, setOpen] = useState(expanded)

  const toggleOpen = () => setOpen(prev => !prev)

  return (
    <Wrapper disabled={disabled} open={open}>
      <PanelHeader onClick={toggleOpen} alt={alt}>
        <PanelTitle className='expandable-panel_title'>{title}</PanelTitle>
        <IconWrapper>
          {open ? <IconChevronUp /> : <IconChevronDown />}
        </IconWrapper>
      </PanelHeader>
      <Collapse in={open}>
        <PanelContent>{children}</PanelContent>
      </Collapse>
    </Wrapper>
  )
}

export default ExpandablePanel

const Wrapper = styled.div<{
  disabled?: boolean
  open: boolean
}>`
  margin-bottom: 8px;
  
  ${({ disabled }) => disabled && css`
    pointer-events: none;
    color: #ccc;
  `}
`

const PanelHeader = styled.div<{
  alt: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
  padding: 14px;
  background-color: ${({ theme }) => theme.colors.primaryLight};
  border-radius: 8px;
  
  ${({ alt }) => alt && css`
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
  `}
`

const PanelTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: .5px;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`

const PanelContent = styled.div`
  padding: 24px 16px;
`
