import React from 'react'
import styled from 'styled-components'
import {mobileCss} from 'utils/theme'

interface CategorySectionProps {
  title: string;
  subtitle: string;
  listItems: string[];
}

const CategorySection: React.FC<CategorySectionProps> = ({
  title,
  subtitle,
  listItems,
}) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <List>
        {listItems.map((item, index) => (
          <ListItem key={index}>{item}</ListItem>
        ))}
      </List>
    </Wrapper>
  );
};

export default CategorySection;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 16px;
  margin-bottom: 30px;
`

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  
  ${mobileCss(`
    font-size: 16px;
  `)}
`

const Subtitle = styled.div`
  font-size: 18px;

  ${mobileCss(`
    font-size: 14px;
  `)}
`

const List = styled.ul`
  padding-left: 30px;

  ${mobileCss(`
    font-size: 14px;
  `)}
`

const ListItem = styled.li``
