import React from 'react'
import ExpandablePanel from 'components/ExpandablePanel'

import FormTemplate from 'components/FormTemplate'
import {SectionDataTypes} from 'utils/sectionDataTypes'

const successData: SectionDataTypes = {
  formType: 'success',
  title: 'Kryteria dla kategorii SUKCES, które Kapituła bierze pod uwagę przy wyborze laureatów:',
  subtitle: 'OSOBA NOMINOWANA WYKAZUJE SIĘ WYJĄTKOWYMI OSIĄGNIĘCIAMI BIZNESOWYMI, TAKIMI JAK:',
  listItems: [
    'zrealizowanie spektakularnego projektu który wywarł trwały wpływ na rynek;',
    'budowa silnej marki;',
    'wypromowaie polskiej marki za granicą;',
    'spektakularny wzrost udziałów w rynku;',
    'bezkonkurencyjne zrealizowanie projektu.'
  ]
}

const Innovations = () => {

  return (
    <ExpandablePanel title='Sukces'>
      <FormTemplate sectionData={successData}/>
    </ExpandablePanel>
  )
}

export default Innovations


// isPublicCompany?: boolean
