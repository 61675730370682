import React from 'react'
import AdminPageWrapper from 'admin/components/AdminPageWrapper'
import InnovationCandidateForm from 'admin/components/InnovationCandidateForm'

const AddInnovationCandidate = () => {
  return (
    <AdminPageWrapper>
      <InnovationCandidateForm />
    </AdminPageWrapper>
  )
}

export default AddInnovationCandidate
