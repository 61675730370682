import React, { useEffect, useState } from 'react'
import { supabase } from 'utils/supabase'
import { VoterTypes } from 'admin/pages/Voters'
import FinalSubmissionsTable from 'admin/components/FinalSubmissionsTable'

const FinalVotingResults = () => {

  const [voters, setVoters] = useState<VoterTypes[] | []>([])

  const getVoters = async () => {
    try {
      const response = await supabase
        .from('voters')
        .select()

      const data = response.data as VoterTypes[]
      setVoters(data)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getVoters()
  }, [])

  return (
    <div>
      { voters && <FinalSubmissionsTable voters={voters} />}
    </div>
  )
}

export default FinalVotingResults
