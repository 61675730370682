import React from 'react'
import AdminPageWrapper from 'admin/components/AdminPageWrapper'
import SuccessCandidateForm from 'admin/components/SuccessCandidateForm'

const AddSuccessCandidate = () => {
  return (
    <AdminPageWrapper>
      <SuccessCandidateForm />
    </AdminPageWrapper>
  )
}

export default AddSuccessCandidate
