import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {TextField} from '@mui/material'

interface MiniCaptchaProps {
  onCaptchaValidation: (isValid: boolean) => void
  error: boolean
}

export const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const MiniCaptcha: React.FC<MiniCaptchaProps> = ({onCaptchaValidation}) => {
  const [numbers, setNumbers] = useState({num1: 0, num2: 0})
  const [captchaAnswer, setCaptchaAnswer] = useState('')
  const [error, setError] = useState(false)

  useEffect(() => {
    const num1 = getRandomNumber(1, 10)
    const num2 = getRandomNumber(1, 10)
    setNumbers({num1, num2})
    setCaptchaAnswer('')
    setError(false)
  }, [])

  const checkCaptcha = () => {
    const answer = numbers.num1 + numbers.num2
    if (parseInt(captchaAnswer, 10) === answer) {
      onCaptchaValidation(true)
    } else {
      onCaptchaValidation(false)
      setError(true)
    }
  }

  useEffect(() => {
    checkCaptcha()
  }, [captchaAnswer])

  return (
    <CaptchaContainer>
      <CaptchaHeader>
        Podaj sumę poniższych cyfr:
      </CaptchaHeader>
      <Captcha>
        <CaptchaText>
          {numbers.num1} + {numbers.num2} =
        </CaptchaText>
        <NarrowField>
        <CustomTextField
          inputProps={{ maxLength: 2 }}
          name='captcha'
          id='captcha_answer'
          variant='standard'
          value={captchaAnswer}
          onChange={(e) => setCaptchaAnswer(e.target.value)}
          error={error}
        />
        </NarrowField>
      </Captcha>
    </CaptchaContainer>
  )
}

export default MiniCaptcha

const CaptchaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
`
const CaptchaHeader = styled.div`
  margin: 10px 0;
  color: ${({theme}) => theme.colors.primary};
`
const Captcha = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  color: ${({theme}) => theme.colors.primary};
`

const CaptchaText = styled.div`
  padding-top: -3px;
`

const CustomTextField = styled(TextField)({
  '& .MuiInput-underline:after': {
    borderColor: '#c59b51', // Change the underline color after input
  },
  '& .MuiInput-underline:before': {
    borderColor: '#c59b51', // Change the underline color before input
  },
  '& .MuiInput-input': {
    color: '#c59b51', // Change the input text color
  },
});
const NarrowField = styled.div`
  margin-top: 1px;
  max-width: 18px;
`
