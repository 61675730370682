import React, { useEffect, useState } from 'react'
import { isNotNilOrEmpty } from 'utils/ramda'
import { VoterTypes } from 'admin/pages/Voters'
import styled from 'styled-components'
import { mobileCss } from 'utils/theme'
import { isNotNil } from 'ramda'
import { supabase } from 'utils/supabase'
import { VotingTableRowTypes } from 'admin/components/VotingTable'

interface SumbissionsTableProps {
  voters: VoterTypes[];
}

const VotersSubmissionsTable = ({ voters }: SumbissionsTableProps) => {
  const [votersData, setVotersData] = useState([])

  useEffect(() => {
    const getVotersData = async () => {
      const response = await supabase
        .from('candidates')
        .select()

      const data = response.data as VotingTableRowTypes[]

      const success = data.filter(candidate => candidate.type === 'success').map(c => c.id)
      const innovation = data.filter(candidate => candidate.type === 'innovation').map(c => c.id)
      const charity = data.filter(candidate => candidate.type === 'charity').map(c => c.id)

      return voters.map(voter => {
        const voterVotes = Object.entries(voter.votes_initial)
          .map(([key, value]) => {
            return {
              id: key,
              sortingName: data.find(candidate => candidate.id === key)?.sortingName || '',
              ...value,
            }})
          .sort((a, b) => {
            return a.sortingName.localeCompare(b.sortingName)
          })

        return {
          name: voter.name,
          votes: {
            success: voterVotes.filter(v => success.includes(v.id)),
            innovation: voterVotes.filter(v => innovation.includes(v.id)),
            charity: voterVotes.filter(v => charity.includes(v.id))
          }
        }
      })
    }

    getVotersData()
      .then(resp => {
        // @ts-ignore
        setVotersData(resp)
      })
  }, [voters])

  const getTable = (type: string) => {
    let maxVotesCount = 0;
    let maxVotesArray: unknown[] = []

    // @ts-ignore
    const tableVotersData = votersData.map(v => ({ ...v, votes: v.votes[type] }))

    tableVotersData.forEach((voter) => {
      const votesCount = voter.votes.length
      if (votesCount > maxVotesCount) {
        maxVotesCount = votesCount
        maxVotesArray = voter.votes
      }
    })


    const headers = (
      <thead>
      <tr>
        <th>Nazwa</th>
        {
          maxVotesArray.map(vote => {
            // @ts-ignore
            return <th key={vote.nominee}>{vote.nominee}</th>
          })
        }
      </tr>
      </thead>
    )

    const result = (
      <tr>
        <td />
        {
          maxVotesArray.map((vote) => {
            const countVotes = tableVotersData.reduce((acc, voter) => {
              // @ts-ignore
              const voterVote = voter.votes.find((v: VoterTypes) => v.id === vote.id)?.value || false
              if (voterVote) {
                return acc + 1
              } else {
                return acc
              }
            }, 0)

            return <td>{countVotes}</td>
          })
        }
      </tr>
    )

    return (
      <Table>
        {headers}
        <tbody>
        {
          // @ts-ignore
          tableVotersData.map(voter => (
            <tr key={voter.name}>
              <td>
                {voter.name}
              </td>
              {maxVotesArray.map((_, index) => {
                const vote = voter.votes[index]

                return (
                  isNotNil(vote)
                    ? <td>{vote.value ? 'TAK' : 'NIE'}</td>
                    : <td>---</td>
                )
              })}
            </tr>
          ))
        }
        {result}
        </tbody>
      </Table>
    )
  }

  return isNotNilOrEmpty(voters) ? (
    <div>
      <SectionWrapper>
        <SectionTitle>Sukces</SectionTitle>
        <TableWrapper>
          {getTable('success')}
        </TableWrapper>
      </SectionWrapper>
      <SectionWrapper>
        <SectionTitle>Innowacje</SectionTitle>
        <TableWrapper>
          {getTable('innovation')}
        </TableWrapper>
      </SectionWrapper>
      <SectionWrapper>
        <SectionTitle>Działalność społeczna</SectionTitle>
        <TableWrapper>
          {getTable('charity')}
        </TableWrapper>
      </SectionWrapper>
    </div>
  ) : (
    <div>Brak osób głosujących</div>
  )
}

export default VotersSubmissionsTable;

const TableWrapper = styled.div`
  min-width: 95vw !important;
  max-width: 95vw !important;
  overflow-x: auto;
`

const Table = styled.table`
  border-spacing: 0;
  font-size: 14px;
  
  th,
  td {
    font-size: 12px;
    padding: 8px;
    border-bottom: 1px solid #f2f2f2;
  }

  th {
    text-align: center;
    background-color: #ccc;
  }

  td:first-child {
    font-weight: bold;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  ${mobileCss(`
    th,
    td {
      padding: 8px;
    }
  `)}
`

const SectionWrapper = styled.div`
  margin: 20px 0 40px;
`

const SectionTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 16px;
`
