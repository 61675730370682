export const defaultValues = {
  basicDetails: {
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    phoneNumber: ''
  },
  innovations: {
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    phoneNumber: '',
    industry: '',
    isPublicCompany: false,
    website: '',
    position: '',
    justification: ''
  },
  success: {
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    phoneNumber: '',
    industry: '',
    isPublicCompany: false,
    website: '',
    position: '',
    justification: ''
  },
  charity: {
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    phoneNumber: '',
    industry: '',
    isPublicCompany: false,
    website: '',
    position: '',
    justification: ''
  }
}
