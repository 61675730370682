import React from 'react'
import styled, { css } from 'styled-components'

interface BooleanInputProps {
  name: string
  label: string
  value: boolean | string | null
  onChange: (name: string, value: boolean | null | string) => void
}

const BooleanInput = ({ name, label, value, onChange }: BooleanInputProps) => {
  const handleValueChange = (value: boolean | null | string) => () => {
    onChange(name, String(value))
  }

  const val = value === 'true' || value === true

  return (
    <Wrapper>
      <Label>{label}</Label>
      <Buttons>
        <SingleButton active={val} onClick={handleValueChange('true')}>
          TAK
        </SingleButton>
        <SingleButton active={!val} onClick={handleValueChange('false')}>
          NIE
        </SingleButton>
      </Buttons>
    </Wrapper>
  )
}

export default BooleanInput

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Label = styled.div`
  
`

const Buttons = styled.div`
  display: flex;
  gap: 8px;
`

const SingleButton = styled.div<{
  active: boolean
}>`
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: all .3s;
  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.colors.primaryLight};
    background-color: ${({ theme }) => theme.colors.primaryLight};
  }
  
  ${({ active, theme }) => active && css`
    background-color: ${theme.colors.primary};
    color: #fff;
  `}
`
